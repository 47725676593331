globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"5ae6c93b2df974c3b7ab3d156679e05e4100fb40"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/hcp";
import * as Sentry from '@sentry/nextjs';
import type { BrowserOptions } from '@sentry/nextjs';
import type { Client } from '@sentry/types';

import sharedSentryOptions from './shared-sentry-options';

export const initSentry = (
    additionalOptions: BrowserOptions
): Client | undefined =>
    Sentry.init({
        ...sharedSentryOptions,
        ...additionalOptions,
    });
